import * as React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Home from "./Sezioni";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import { theme } from "./conf/theme";
import { ThemeProvider, styled } from "@mui/material/styles";
import ResetPass from "./Auth/ResetPass";
import * as count from "../src/rematch/count";
import { Provider } from "react-redux";
import createPersistPlugin, { getPersistor } from "@rematch/persist";
import { PersistGate } from "redux-persist/lib/integration/react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { init } from "@rematch/core";
import ChangePassword from "./Auth/ChangePassword";
import ThankyouPage from "./Auth/ThankyouPage";
import Cataloghi from "./Cataloghi/Cataloghi";
import Accompagnatori from "./Accompagnatori/Accompagnatori";
import Organismi from "./Organismi/Organismi";
import News from "./News/News";
import NewsDetails from "./News/NewsDetails";
import TrekMagazine from "./TrekMagazine/TrekMagazine";
import TrekMagazineDetails from "./TrekMagazine/TrekMagazineDetails";
import Trek from "./Trek/Trek";
import TrekItalia from "./Trek/TrekItalia";
import "./conf/i18n";
import Faq from "./Faq/Faq";
import Contatti from "./Contatti/Contatti";
import Valori from "./Valori/Valori";
import AreaGiovani from "./AreaGiovani/AreaGiovani";
import IniziativeAmbiente from "./IniziativeAmbiente/IniziativeAmbiente";
import Accoglienza from "./Accoglienza/Accoglienza";
import TrekSolidali from "./TrekSolidali/TrekSolidali";
import Formazione from "./Formazione/Formazione";
import VitaAssociativa from "./VitaAssociativa/VitaAssociativa";
import Associazione from "./Associazione/Associazione";
import DatiPersonali from "./DatiPersonali/DatiPersonali";
import TrekSalvati from "./TrekSalvati/TrekSalvati";
import TrekEffettuati from "./TrekEffettuati/TrekEffettuati";
import InscrizioniCorsiEventi from "./IscrizioniCorsiEventi/InscrizioniCorsiEventi";
import InscrizioniTrek from "./IscrizioniTrek/InscrizioniTrek";
import PrivateRoutes from "./components/PrivateRoutes";
import { tokenLoaer } from "./Auth/auth";
import TesseraAssociativa from "./TesseraAssociativa/TesseraAssociativa";
import Privacy from "./Privacy/Privacy";
import BorsellinoElettronico from "./BorsellinoElettronico/BorsellinoElettronico";
import PaymentSuccess from "./BorsellinoElettronico/PaymentSuccess";
import PaymentFailed from "./BorsellinoElettronico/PaymentFailed";
import Sezioni from "./Sezioni/Sezioni";
import LoginPage from "./Auth/LoginPage.jsx";
import PagineDinamiche from "./Sezioni/PagineDinamiche";
import Search from "./Search/Search";
import Eventi from "./Eventi/Eventi";
import ItemEvento from "./Eventi/ItemEvento";
import AccompagnatoreSingolo from "./AccompagnatoreSingolo/AccompagnatoreSingolo";
import TrekEstero from "./Trek/TrekEstero";
import TrekGeo from "./Trek/TrekGeo";
import SingleEvent from "./Eventi/SingleEvent";
import Corsi from "./Corsi/Corsi";
import SingleCourse from "./Corsi/SingleCourse";
import TrekItalia2 from "./Trek/trekitalia2";
import ChiSiamo from "./ChiSiamo/ChiSiamo";
import Volontari from "./Volontari/Volontari";
import CopertureAssicurative from "./CopertureAssicurative/CopertureAssicurative";
import env from "./conf/variables";
import Manutenzione from "./components/Manutenzione";
import StatutoRegolamenti from "./StatutoRegolamenti/StatutoRegolamenti";
import BilancioSociale from "./BilancioSociale/BilancioSociale";
import BilanciAnnuali from "./BilanciAnnuali/BilanciAnnuali";
import PrivacyPublic from "./Privacy/PrivacyPublic.jsx";
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Switch } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CookiePolicy from "./Cookie/CookiePolicy.jsx";
import BaseTemplate from "./components/BaseTemplate.jsx";
import ValutazioneTrek from "./Classificazione/ValutazioneTrek.jsx";
import TipologiaTrek from "./Classificazione/TipologiaTrek.jsx";
import Equipaggiamento from "./PreparatiTrek/Equipaggiamento.jsx";
import Abbigliamento from "./PreparatiTrek/Abbigliamento.jsx";
import Allenamento from "./PreparatiTrek/Allenamento.jsx";
import Alimentazione from "./PreparatiTrek/Alimentazione.jsx";
import ComeIscriversiAiTrek from "./ComeIscriversiAiTrek/ComeIscriversiAiTrek.js";

const persistPlugin = createPersistPlugin({
    key: "trekWeb",
    storage: AsyncStorage,
});
const store = init({
    name: "trekWeb",
    models: count,
    plugins: [persistPlugin],
});

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

export function CookieConsent() {

    const [openSelectionCookieDialog, setOpenSelectionCookieDialog] = React.useState(false)
    const [checkFunzionalita, setCheckFunzionalita] = React.useState(false)
    const [checkEsperienza, setCheckEsperienza] = React.useState(false)
    const [checkMisurazione, setCheckMisurazione] = React.useState(false)
    const [checkMarketing, setCheckMarketing] = React.useState(false)
    const [consensi, setConsensi] = React.useState(() => caricaConsensiCookie());
    const [openPreferenze, setOpenPreferenze] = React.useState(false);

    React.useEffect(() => {
        salvaConsensiCookie(consensi);
    }, [consensi]);

    // Funzione per gestire il consenso per un particolare tipo di cookie
    function gestisciConsensoCookie(tipoCookie, accettato) {
        setConsensi(prevConsensi => ({
            ...prevConsensi,
            [tipoCookie]: accettato
        }));
    }

    // Funzione per salvare i consensi nel localStorage
    function salvaConsensiCookie(consensi) {
        const scadenza = new Date();
        scadenza.setTime(scadenza.getTime() + (365 * 24 * 60 * 60 * 1000));
        const scadenzaString = `expires=${scadenza.toUTCString()}`;
        document.cookie = `consensiCookie=${JSON.stringify(consensi)}; ${scadenzaString}; path=/`;
    }

    // Funzione per caricare i consensi salvati nel localStorage
    function caricaConsensiCookie() {
        const cookies = document.cookie.split(';');
        let consensiSalvati = {};
        cookies.forEach(cookie => {
            const [name, value] = cookie.trim().split('=');
            if (name === 'consensiCookie') {
                consensiSalvati = JSON.parse(value);
            }
        });
        return consensiSalvati;
    }

    return (
        <>
            <Dialog
                open={!Object.keys(consensi)?.length}
                onClose={() => {
                    gestisciConsensoCookie('necessari', true)
                    gestisciConsensoCookie('funzionalita', true)
                    gestisciConsensoCookie('esperienza', true)
                    gestisciConsensoCookie('misurazione', true)
                    gestisciConsensoCookie('marketing', true)
                }}
                fullWidth
            >
                <DialogTitle>Cookie Policy</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Noi e terze parti selezionate utilizziamo cookie o tecnologie simili per finalità tecniche e, con il tuo consenso, anche per altre finalità come specificato nella cookie policy.

                        Usa il pulsante “Accetta” per acconsentire. Usa il pulsante “Rifiuta” o chiudi questa informativa per continuare senza accettare.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className="flex justify-between w-full">
                        <Button className="!text-black" onClick={() => { setOpenSelectionCookieDialog(true) }}>Scopri di più</Button>
                        <div>
                            <Button className="!text-black" onClick={() => {
                                gestisciConsensoCookie('necessari', true)
                                gestisciConsensoCookie('funzionalita', false)
                                gestisciConsensoCookie('esperienza', false)
                                gestisciConsensoCookie('misurazione', false)
                                gestisciConsensoCookie('marketing', false)
                            }}>Rifiuta</Button>
                            <Button className="!text-black" onClick={() => {
                                gestisciConsensoCookie('necessari', true)
                                gestisciConsensoCookie('funzionalita', true)
                                gestisciConsensoCookie('esperienza', true)
                                gestisciConsensoCookie('misurazione', true)
                                gestisciConsensoCookie('marketing', true)
                            }} autoFocus>
                                Accetta
                            </Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openSelectionCookieDialog}
                onClose={() => {
                    setOpenSelectionCookieDialog(false)
                }}
                fullWidth
            >
                <DialogTitle className="bg-[#df5b23] text-white">Cookie Policy</DialogTitle>
                <DialogActions className="">
                    <Button className="!text-black" onClick={() => {
                        setCheckEsperienza(false)
                        setCheckFunzionalita(false)
                        setCheckMarketing(false)
                        setCheckMisurazione(false)
                    }}>Rifiuta tutto</Button>
                    <Button className="!text-black" onClick={() => {
                        setCheckEsperienza(true)
                        setCheckFunzionalita(true)
                        setCheckMarketing(true)
                        setCheckMisurazione(true)
                    }}>Accetta tutto</Button>
                </DialogActions>
                <div className="overflow-auto max-h-[450px] relative ">
                    <DialogContent className="relative" sx={{ padding: 0 }}>
                        <Divider />
                        <div className="px-4">
                            <h2 className="text-4xl mt-4">
                                Le tue preferenze relative al consenso per le tecnologie di tracciamento
                            </h2>
                            <p className="mt-5 mb-5">
                                Le opzioni disponibili in questa sezione ti permettono di personalizzare le preferenze relative al consenso per qualsiasi tecnologia di tracciamento utilizzata per le finalità descritte di seguito. Per ottenere ulteriori informazioni in merito all'utilità e al funzionamento di tali strumenti di tracciamento, fai riferimento alla <a className="text-[#df5b23]" href={`https://www.trekkingitalia.org/cookie-policy`}>cookie policy</a>. Tieni presente che il rifiuto del consenso per una finalità particolare può rendere le relative funzioni non disponibili.
                            </p>
                        </div>
                        <Divider />
                        <div className="px-4">
                            <div className="w-full flex justify-between items-center ">
                                <h3>Necessari</h3>
                                <FormControlLabel
                                    disabled
                                    value={true}
                                    control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                                />
                            </div>
                            <p className="my-5">Questi strumenti di tracciamento sono strettamente necessari per garantire il funzionamento e la fornitura del servizio che ci hai richiesto e, pertanto, non richiedono il tuo consenso.</p>
                            {/* <Accordion className="mb-5">
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    Accordion 1
                                </AccordionSummary>
                                <AccordionDetails>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </AccordionDetails>
                            </Accordion> */}
                            <Divider />
                            <div className="w-full flex justify-between items-center ">
                                <h3>Funzionalità</h3>
                                <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }}
                                        checked={checkFunzionalita}
                                        onChange={(e) => {
                                            setCheckFunzionalita(e.target.checked)
                                        }} />}
                                />
                            </div>
                            <p className="my-5">Questi strumenti di tracciamento abilitano semplici interazioni e funzionalità che ti permettono di accedere a determinate risorse del nostro servizio e ti consentono di comunicare più facilmente con noi.</p>
                            {/* <Accordion className="mb-5">
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    Accordion 1
                                </AccordionSummary>
                                <AccordionDetails>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </AccordionDetails>
                            </Accordion> */}
                            <Divider />
                            <div className="w-full flex justify-between items-center ">
                                <h3>Esperienza</h3>
                                <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }}
                                        checked={checkEsperienza}
                                        onChange={(e) => {
                                            setCheckEsperienza(e.target.checked)
                                        }} />}
                                />
                            </div>
                            <p className="my-5">Questi strumenti di tracciamento ci permettono di migliorare la qualità della tua esperienza utente e consentono le interazioni con piattaforme, reti e contenuti esterni.</p>
                            {/* <Accordion className="mb-5">
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    Accordion 1
                                </AccordionSummary>
                                <AccordionDetails>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </AccordionDetails>
                            </Accordion> */}
                            <Divider />
                            <div className="w-full flex justify-between items-center ">
                                <h3>Misurazione</h3>
                                <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }}
                                        checked={checkMisurazione}
                                        onChange={(e) => {
                                            setCheckMisurazione(e.target.checked)
                                        }} />}
                                />
                            </div>
                            <p className="my-5">Questi strumenti di tracciamento ci permettono di misurare il traffico e analizzare il tuo comportamento per migliorare il nostro servizio.</p>
                            {/* <Accordion className="mb-5">
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    Accordion 1
                                </AccordionSummary>
                                <AccordionDetails>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </AccordionDetails>
                            </Accordion> */}
                            <Divider />
                            <div className="w-full flex justify-between items-center ">
                                <h3>Marketing</h3>
                                <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }}
                                        checked={checkMarketing}
                                        onChange={(e) => {
                                            setCheckMarketing(e.target.checked)
                                        }} />}
                                />
                            </div>
                            <p className="my-5">Questi strumenti di tracciamento ci permettono di fornirti contenuti marketing o annunci personalizzati e di misurarne la performance.</p>
                            {/* <Accordion className="mb-5">
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    Accordion 1
                                </AccordionSummary>
                                <AccordionDetails>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </AccordionDetails>
                            </Accordion> */}
                            <Divider />

                        </div>

                    </DialogContent>
                </div>
                <DialogActions>
                    <div className="flex justify-between w-full">
                        <Button className="!text-black" onClick={() => { setOpenSelectionCookieDialog(false) }}>Indietro</Button>
                        <Button className="!text-black" onClick={() => {
                            gestisciConsensoCookie('necessari', true)
                            gestisciConsensoCookie('funzionalita', checkFunzionalita)
                            gestisciConsensoCookie('esperienza', checkEsperienza)
                            gestisciConsensoCookie('misurazione', checkMisurazione)
                            gestisciConsensoCookie('marketing', checkMarketing)
                            setOpenSelectionCookieDialog(false)
                        }}>Salva e continua</Button>
                    </div>
                </DialogActions>
            </Dialog>

            {
                Object.keys(consensi)?.length ?
                    <button onClick={() => { setOpenSelectionCookieDialog(true) }} className="bg-white border rounded shadow-sm z-[999] fixed bottom-[10px] right-[10px] transform px-2">
                        cookie policy
                    </button>
                    : ''
            }
        </>
    )
}

function App() {
    const persistor = getPersistor();

    return (
        <>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <BrowserRouter>
                        <ThemeProvider theme={theme}>
                            <Routes>
                                <Route path="/" element={<BaseTemplate />} >
                                    <Route path="/" element={<Home />} />
                                    <Route path="/login" element={<LoginPage />} />
                                    <Route path="/register" element={<Register />} />
                                    <Route path="/register/socio" element={<Register socio={true} />} />
                                    <Route path="/reset-pass" element={<ResetPass />} />
                                    <Route path="/thank-you" element={<ThankyouPage />} />
                                    <Route path="/password-reset" element={<ChangePassword />} />
                                    <Route path="/cataloghi" element={<Cataloghi />} />
                                    <Route path="/accompagnatori" element={<Accompagnatori />} />
                                    <Route path="/accompagnatori/:id" element={<AccompagnatoreSingolo />} />
                                    <Route path="/organismi" element={<Organismi />} />
                                    <Route path="/vita-associativa" element={<VitaAssociativa />} />
                                    <Route path="/news" element={<News />} />
                                    <Route path="/news/:id" element={<NewsDetails />} />
                                    <Route path="/eventi" element={<Eventi />} />
                                    <Route path="/eventi/:id" element={<SingleEvent />} />
                                    <Route path="/corsi" element={<Corsi />} />
                                    <Route path="/corsi/:id" element={<SingleCourse />} />
                                    <Route path="/trek-magazine" element={<TrekMagazine />} />
                                    <Route
                                        path="/trek-magazine/:id"
                                        element={<TrekMagazineDetails />}
                                    />
                                    <Route path="/trek/:id" element={<Trek />} />
                                    <Route
                                        key={"trek-italia"}
                                        path="/trek-italia"
                                        element={<TrekItalia2 pos="Italia" />}
                                    />
                                    <Route
                                        key={"trek-estero"}
                                        path="/trek-estero"
                                        element={<TrekEstero />}
                                    />
                                    <Route
                                        key={"vicino-a-me"}
                                        path="/vicino-a-me"
                                        element={<TrekGeo />}
                                    />
                                    <Route
                                        key={"trek-italia-prova"}
                                        path="/trek-estero-prova"
                                        element={<TrekItalia2 pos="Italia" />}
                                    />
                                    <Route
                                        key={"tipologia-trek"}
                                        path="/tipologia-trek"
                                        element={<TipologiaTrek />}
                                    />

                                    <Route
                                        key={"equipaggiamento"}
                                        path="/equipaggiamento"
                                        element={<Equipaggiamento />}
                                    />
                                    <Route
                                        key={"abbigliamento"}
                                        path="/abbigliamento"
                                        element={<Abbigliamento />}
                                    />
                                    <Route
                                        key={"allenamento"}
                                        path="/allenamento"
                                        element={<Allenamento />}
                                    />
                                    <Route
                                        key={"alimentazione"}
                                        path="/alimentazione"
                                        element={<Alimentazione />}
                                    />
                                    <Route
                                        key={"valutazione-trek"}
                                        path="/valutazione-trek"
                                        element={<ValutazioneTrek />}
                                    />
                                    <Route
                                        key={"come-iscriversi-ai-trek"}
                                        path="/come-iscriversi-ai-trek"
                                        element={<ComeIscriversiAiTrek />}
                                    />

                                    <Route path="/i-nostri-volontari" element={<Volontari />} />
                                    <Route path="/faq" element={<Faq />} />
                                    <Route path="/coperture-assicurative" element={<CopertureAssicurative />} />
                                    <Route path="/contatti" element={<Contatti />} />
                                    <Route path="/valori" element={<Valori />} />
                                    <Route path="/chi-siamo" element={<ChiSiamo />} />
                                    <Route path="/statuto-e-regolamenti" element={<StatutoRegolamenti />} />
                                    <Route path="/bilancio-sociale" element={<BilancioSociale />} />
                                    <Route path="/bilanci-annuali" element={<BilanciAnnuali />} />
                                    <Route path="/area-giovani" element={<AreaGiovani />} />
                                    <Route
                                        path="/iniziative-per-ambiente"
                                        element={<IniziativeAmbiente />}
                                    />
                                    <Route path="/accoglienza" element={<Accoglienza />} />
                                    <Route path="/trek-solidali" element={<TrekSolidali />} />
                                    <Route path="/formazione" element={<Formazione />} />
                                    <Route path="/associazione" element={<Associazione />} />
                                    <Route path="/sezioni/:sezione" element={<Sezioni />} />
                                    <Route
                                        path="/sezioni/:sezione/:paginatemplate"
                                        element={<PagineDinamiche template={2} />}
                                    />
                                    <Route
                                        path="/sezioni/:sezione/:paginatemplate/:paginatemplatefiglia"
                                        element={<PagineDinamiche template={1} />}
                                    />
                                    <Route path="/search" element={<Search />} />

                                    <Route id="root" loader={tokenLoaer} element={<PrivateRoutes />}>
                                        <Route path="/user" element={<DatiPersonali />} />
                                        <Route path="/carta" element={<TesseraAssociativa />} />
                                        <Route path="/ricerche-salvate" element={<TrekSalvati />} />
                                        <Route path="/iscrizioni-trek" element={<InscrizioniTrek />} />
                                        <Route path="/effettuati" element={<TrekEffettuati />} />
                                        <Route
                                            path="/iscrizioni-corsi-eventi"
                                            element={<InscrizioniCorsiEventi />}
                                        />
                                        <Route
                                            path="/borsellino-elettronico"
                                            element={env.borsellinoFunction ? <BorsellinoElettronico /> : <Manutenzione text="Questa funzionalità non è ancora attiva" />}
                                        />
                                        <Route path="/payment-success" element={<PaymentSuccess />} />
                                        <Route path="/payment-failed" element={<PaymentFailed />} />
                                        <Route path="/privacy" element={<Privacy />} />
                                    </Route>
                                    <Route path="/privacy-policy" element={<PrivacyPublic />} />
                                </Route>
                                <Route path="/cookie-policy" element={<CookiePolicy />} />
                            </Routes>
                            {/* <Routes>
                                <Route path="/*" element={<CookieConsent />} />
                            </Routes>
                            <Routes>
                                <Route path="/cookie-policy" element={<CookiePolicy />} />
                            </Routes> */}
                        </ThemeProvider>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
            {/* <CookieBanner
                message="Questo sito utilizza i cookie per migliorare la tua esperienza di navigazione. Puoi scegliere quali cookie accettare o rifiutare."
                acceptButtonText="Accetta tutti"
                declineButtonText="Rifiuta tutti"
                managePreferencesButtonText="Gestisci preferenze"
                preferencesDialogTitle="Preferenze sui cookie"
                necessaryOptionText="Necessari"
                necessaryOptionDescription="Cookie essenziali per il funzionamento del sito"
                preferencesOptionText="Preferenze"
                preferencesOptionDescription="Cookie che memorizzano le tue preferenze personali"
                statisticsOptionText="Statistiche"
                statisticsOptionDescription="Cookie che raccolgono informazioni statistiche sulle visite degli utenti"
                marketingOptionText="Marketing"
                marketingOptionDescription="Cookie che mostrano annunci personalizzati in base alle preferenze degli utenti"
                onAcceptAll={() => {
                    // Fai qualcosa quando l'utente accetta tutti i cookie
                }}
                onDeclineAll={() => {
                    // Fai qualcosa quando l'utente rifiuta tutti i cookie
                }}
                onAcceptPreferences={() => {
                    // Fai qualcosa quando l'utente accetta i cookie di preferenze
                }}
                onAcceptStatistics={() => {
                    // Fai qualcosa quando l'utente accetta i cookie di statistiche
                }}
                onAcceptMarketing={() => {
                    // Fai qualcosa quando l'utente accetta i cookie di marketing
                }}
            /> */}
        </>

    );
}

export default App;
