const env = {
    secret_key: 'Toad',
    url_panel: "https://panel.trekkingitalia.org/",
    url_api_panel: "https://bfb.trekkingitalia.org/api/v1",
    url_api_without_api: "https://bff.trekkingitalia.org/",
    url_api: "https://bff.trekkingitalia.org/api/v1",
    borsellinoFunction: true,
    version: '1.2.32'
}

export default env